import axios from "axios";

export class RequestServerClass {
  public serverObj: any;

  constructor(opt: any) {
    const obj = Object.assign({}, opt);
    
    this.serverObj = axios.create(obj);
  }
}
