/*
 * @Author: alanChen
 * @Date: 2022-01-12 13:46:10
 * @LastEditTime: 2022-01-12 13:53:37
 * @LastEditors: your name
 * @Description: loading白名单
 * @FilePath: \project\src\filter\RequireFilterConfig.ts
 * tel:15584312340/mail:15584312340@163.com
 */

const urlArr: Array<string> = [
  '/system/api_8',
]

function FnRequireFilterConfig(url:string) {
  if (urlArr.includes(url)) {
    return false;
  } else {
    return true
  }
}

export default FnRequireFilterConfig;
