import $ from "jquery";
const publishObjectPath: any = {
  serverPath: "",
};
Object.defineProperty(publishObjectPath, "serverPath", {
  set(data: any) {
    this.value = data;
  },
});
// 获取服务端全部服务根地址信息
function pathCallback() {
  $.ajax({
    url: "./json/publishObjectPath.json",
    async: false,
    success(data: any) {
      publishObjectPath.serverPath = data;
    },
  });
}
pathCallback();

export default publishObjectPath;
