/*
 * @Author: alanChen
 * @Date: 2022-01-16 18:29:53
 * @LastEditTime: 2022-01-17 15:46:00
 * @LastEditors: your name
 * @Description: 下载类
 * @FilePath: \project\src\apiServer\feature\downServer\DownServer.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { RequestServerClass } from "@/util/downRequest";
import AES_CBC from "@/util/encryption";
export class DownServer {
  public rSerivce: any;

  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // 对称加密装配函数
  public FnEncryptionFactory(
    params: any,
    url: string,
    requestType: string
  ): any {
    this.rSerivce.serverObj.defaults.responseType = "blob";
    return this.rSerivce.serverObj[requestType](url, params);
  }

  // 获取文件流
  public GetFileStream(params: any): any {
    const url = "/system/api_11";
    return this.FnEncryptionFactory(params, url, "post");
  }

  // 测试
  public TestDown(params: any): any {
    const url = "/system/api_12";
    return this.FnEncryptionFactory(params, url, "post");
  }
}
