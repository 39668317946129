/*
 * @Author: alanChen
 * @Date: 2021-12-19 18:03:27
 * @LastEditTime: 2022-01-16 20:59:17
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \project\src\apiServer\installServer.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import publishObjectPath from '../util/configRegistry';
const serviceBusPath = publishObjectPath.value && publishObjectPath.value.serviceBus;
import {RequestServerClass} from '../util/request';
import {RequireFilter} from '../filter/RequireFilter';
import {DemoServer} from './feature/demoServer/DemoServer';
import {MainServer} from './feature/mainServer/MainServer';
import {DownServer} from './feature/downServer/DownServer';

const requireFilter: RequireFilter = new RequireFilter();
const injectFilter: any = (servObj: any) => {
    requireFilter.axiosFilter.start(servObj);
    requireFilter.axiosFilter.end(servObj);
};

const demoServer = new DemoServer({baseURL: serviceBusPath},injectFilter);
const mainServer = new MainServer({baseURL: serviceBusPath},injectFilter);
const downServer = new DownServer({baseURL: serviceBusPath},injectFilter);

export  {
    demoServer,
    mainServer,
    downServer
};
