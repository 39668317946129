import { defineComponent, getCurrentInstance } from "vue";
import { RequestServerClass } from "@/util/request";
import AES_CBC from "@/util/encryption";
let test: any = "";
defineComponent({
  setup() {
    const { ctx }: any = getCurrentInstance();
    console.log("demoServer: ====>", ctx);
    debugger;
    test = ctx;
  },
});
export class DemoServer {
  public rSerivce: any;
  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // 对称加密装配函数
  private FnEncryptionFactory(
    params: any,
    encryption: boolean,
    url: string,
    requestType: string
  ): any {
    this.rSerivce.serverObj.defaults.headers.common.secret = encryption
      ? AES_CBC.encrypt(JSON.stringify(params))
      : null;
    this.rSerivce.serverObj.defaults.headers.common.encryption = encryption;
    return this.rSerivce.serverObj[requestType](url, encryption ? "" : params);
  }

  // 测试请求路径
  public PostDemoApi(params: any, encryption: boolean): any {
    const url = "/demoApi/api_1";
    console.log("test", test);
    return this.FnEncryptionFactory(params, encryption, url, "post");
  }
}
