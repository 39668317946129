/*
 * @Author: alanChen
 * @Date: 2022-01-16 20:54:17
 * @LastEditTime: 2022-01-16 21:07:25
 * @LastEditors: your name
 * @Description: 二进制流文件下载
 * @FilePath: \project\src\util\downRequest.ts
 * tel:15584312340/mail:15584312340@163.com
 */

import axios from "axios";

export class RequestServerClass {
  public serverObj: any;

  constructor(opt: any) {
    const obj = Object.assign({}, opt);
    this.serverObj = axios.create(obj);
    // this.serverObj.responseType = 'blob';
  }
}

