/*
 * @Author: alanChen
 * @Date: 2021-09-27 08:46:09
 * @LastEditTime: 2022-01-16 18:05:48
 * @LastEditors: your name
 * @Description:
 * @FilePath: \project\src\apiServer\feature\mainServer\MainServer.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { RequestServerClass } from '@/util/request';
import AES_CBC from '@/util/encryption';
export class MainServer {
  public rSerivce: any;

  constructor(opt: any, axiosFilterFn?: any) {
    this.rSerivce = new RequestServerClass(opt);
    axiosFilterFn.call(this, this.rSerivce.serverObj);
  }

  // 对称加密装配函数
  public FnEncryptionFactory(
    params: any,
    encryption: boolean,
    url: string,
    requestType: string,
  ): any {
    this.rSerivce.serverObj.defaults.headers.common.secret = encryption
      ? AES_CBC.encrypt(JSON.stringify(params))
      : 0;
    this.rSerivce.serverObj.defaults.headers.common.encryption = encryption;
    return this.rSerivce.serverObj[requestType](url, encryption ? '' : params);
  }

  // 获取配置信息
  public SetConfigApi(params: any, encryption: boolean): any {
    const url = '/system/api_1';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }
  // 用户登录
  public LoginApi(params: any, encryption: boolean): any {
    const url = '/system/api_2';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }
  // 应用分类添加
  public AddClassApi(params: any, encryption: boolean): any {
    const url = '/system/api_3';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 查询分类数据
  public GetAddClassApi(params: any, encryption: boolean): any {
    const url = '/system/api_4';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 删除应用分类
  public DelectClassApi(params: any, encryption: boolean): any {
    const url = '/system/api_5';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 获取枚举值
  public GetEnum(params: any, encryption: boolean): any {
    const url = '/system/api_7';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 创建应用
  public CreatedAppApi(params: any, encryption: boolean): any {
    const url = '/system/api_6';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 校验应用名称是否重复
  public VerifyAppApi(params: any, encryption: boolean): any {
    const url = '/system/api_8';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 校验应用名称是否重复
  public Getapp(params: any, encryption: boolean): any {
    const url = '/system/api_9';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 获取该应用下的附件信息
  public GetAppContent(params: any, encryption: boolean): any {
    const url = '/system/api_10';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }

  // 获取文件流
  public GetFileStream(params: any, encryption: boolean): any {
    const url = '/system/api_11';
    return this.FnEncryptionFactory(params, encryption, url, 'post');
  }
}
