/*
 * @Author: alanChen
 * @Date: 2021-11-23 16:16:05
 * @LastEditTime: 2022-01-16 21:02:07
 * @LastEditors: your name
 * @Description:
 * @FilePath: \project\src\filter\RequireFilter.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { config } from "@vue/test-utils";
import Bus from "@/util/bus"; /// mitt 总线程引入
import store from "@/store/index";
import AES_CBC from "@/util/encryption";
import FnRequireFilterConfig from "./RequireFilterConfig";
import { ElMessage } from "element-plus";
let reqNum = 0;
export class RequireFilter {
  public axiosFilter: any;
  constructor() {
    this.axiosFilter = this.axiosFilterCallback();
  }
  public axiosFilterCallback() {
    const tempObj = {
      end: (servObj: any) => {
        servObj.interceptors.response.use((res: any) => {
          if (FnRequireFilterConfig(res.url)) {
            reqNum--;
          }
          if (reqNum <= 0) {
            Bus.$emit("loading", false);
          } else {
            Bus.$emit("loading", true);
          }
          if (res.data.hasOwnProperty("data")) {
            store.commit("$changeStore", {
              name: "vuexUpdataTime",
              value: res.data.updataTime,
            });
            res.data.data.res = res.data.data.encryption
              ? AES_CBC.decrypt(res.data.data.res)
              : res.data.data.res;
            if (res.data.stateMsg === "tokenOverdue") {
              ElMessage("登录超时，请重新登录");
            }
          }
          return res;
        });
      },
      start: (servObj: any) => {
        servObj.interceptors.request.use((config: any) => {
          config.headers.token =
            store.state.vuexUserInfo.system_user_info_token;
          if (FnRequireFilterConfig(config.url)) {
            reqNum++;
            Bus.$emit("loading", true);
          }
          return config;
        });
      },
      all: (servObj: any) => {
        tempObj.start(servObj);
        tempObj.end(servObj);
      },
    };
    return tempObj;
  }
}
